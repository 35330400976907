<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="addAction" icon="plus">添加首页轮播图</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <span slot="action" slot-scope="text, record">
                    <a-button type="link" @click="openEditDialog(record.id)" icon="edit">编辑</a-button>
                    <a-button type="link" @click="delConfirm(record.id)" icon="delete">删除</a-button>
                </span>
                <span slot="file_url" slot-scope="text, record">
                    <small-image-list :img_url="text"/>
                </span>
              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal title="首页轮播图管理" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="actionVisible" @ok="actionData" ok-text="确认" cancel-text="取消" @cancel="cancel">
      <a-form-model ref="formModel" :rules="formModelRules" :model="formModel" layout="vertical"
                    :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="formModel.name" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="图片" prop="file_url">
          <a-alert message="图片尺寸: 750px * 650px" banner  style="margin-bottom: 20px"/>
          <custom-file-list
            :img_url.sync="formModel.file_url"
            :type="'single'"
            @changeCustomFile="changeImgUrl"/>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sort">
          <a-input v-model="formModel.sort" type="number" placeholder="请输入序号"></a-input>
        </a-form-model-item>
        <a-form-item label="跳转地址" prop="return_json">
          <a-textarea v-model="formModel.return_json" :rows="2" placeholder="请输入跳转地址"></a-textarea>
        </a-form-item>
      </a-form-model>
    </a-modal>
  </div>

<!--  <div class="page-cu-container">-->
<!--    <div class="page-cu-top">-->
<!--      <a-form layout="inline">-->
<!--        <a-form-item>-->
<!--          <a-button type="primary" @click="addAction" icon="plus">添加首页轮播图</a-button>-->
<!--        </a-form-item>-->
<!--      </a-form>-->
<!--    </div>-->
<!--    <div class="page-cu-main">-->
<!--      <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">-->
<!--                <span slot="action" slot-scope="text, record">-->
<!--                    <a-button type="link" @click="openEditDialog(record.id)" icon="edit">编辑</a-button>-->
<!--                    <a-button type="link" @click="delConfirm(record.id)" class="text-red" icon="delete">删除</a-button>-->
<!--                </span>-->
<!--        <span slot="file_url" slot-scope="text, record">-->
<!--                    <small-image-list :img_url="text"/>-->
<!--                </span>-->
<!--      </a-table>-->
<!--    </div>-->
<!--    <div class="page-cu-pagination">-->
<!--      <a-pagination :page-size.sync="page.pageSize" :total="page.totalRow" v-model="page.start" @change="changePage"/>-->
<!--    </div>-->
<!--    <div class="page-cu-pagination"></div>-->
<!--    <a-modal title="首页轮播图管理" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"-->
<!--             :visible="actionVisible" @ok="actionData" ok-text="确认" cancel-text="取消" @cancel="cancel">-->
<!--      <a-form-model ref="formModel" :rules="formModelRules" :model="formModel" layout="vertical"-->
<!--                    :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">-->
<!--        <a-form-model-item label="名称" prop="name">-->
<!--          <a-input v-model="formModel.name" placeholder="请输入名称"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="图片" prop="file_url">-->
<!--          <a-alert message="图片尺寸: 750px * 650px" banner  style="margin-bottom: 20px"/>-->
<!--          <custom-file-list-->
<!--            :img_url.sync="formModel.file_url"-->
<!--            :type="'single'"-->
<!--            @changeCustomFile="changeImgUrl"/>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="序号" prop="sort">-->
<!--          <a-input v-model="formModel.sort" type="number" placeholder="请输入序号"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-item label="跳转地址" prop="return_json">-->
<!--          <a-textarea v-model="formModel.return_json" :rows="2" placeholder="请输入跳转地址"></a-textarea>-->
<!--        </a-form-item>-->
<!--      </a-form-model>-->
<!--    </a-modal>-->
<!--  </div>-->
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        formModel: {
          name: '',
          file_url: '',
          return_json: '',
          sort: ''
        },
        formModelRules: {
          name: [{required: true, message: '请输入名称', trigger: 'change'}],
          file_url: [{required: true, message: '请上传图片', trigger: 'click'}],
          return_json: [{required: true, message: '请选择跳转地址', trigger: 'change'}],
          sort: [{required: true, message: '请输入排序数', trigger: 'change'}],
        },
        columns: [
          {
            title: '名称',
            dataIndex: 'name',
          },
          {
            title: '图片',
            dataIndex: 'file_url',
            scopedSlots: {customRender: 'file_url'}
          },
          {
            title: '跳转地址',
            dataIndex: 'return_json',
          },
          {
            title: '序号',
            dataIndex: 'sort',
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
          },
          {
            title: '操作',
            width: 200,
            scopedSlots: {customRender: 'action'},
          },
        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit: 20
        },
        list: [],
        fileList: [],
        actionVisible: false,
        record: '',
        submitFlag: true,
        imgVisible: false,
        previewFileUrl: ''

      }
    },
    mounted() {
      this.getList();

    },
    methods: {
      changeImgUrl(url) {
        this.formModel.file_url = url;
      },

      /**
       * 添加，更新
       *
       */
      actionData() {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = null;
            if (this.formModel['id']) {
              res = await Api.Update(this.formModel);
            } else {
              res = await Api.Save(this.formModel);
            }

            if (res && res['code'] == '0') {
              this.$message.success(res.message);
              this.actionVisible = false;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /*
      * 表单重置
      **/
      resetFormModel() {
        for (let key in this.formModel) {
          this.formModel[key] = '';
        }
      },
      // 菜单选择
      handlerCheck(n, e) {
        this.checkedKeys = {checked: n, halfChecked: e.halfCheckedKeys};
      },
      // 打开添加角色窗
      addAction() {
        this.resetFormModel();
        this.actionVisible = true;
      },
      // 关闭窗口
      cancel() {
        this.actionVisible = false;
      },

      // 删除确认
      async delConfirm(id) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'danger',
          okText: '确定',
          cancelText: '取消',
          content: '您确定要删除吗?',
          async onOk() {
            let res = await Api.Delete({id: id})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      // 列表
      async getList() {
        let res = await Api.List({
          start: this.page.start,
          limit: this.page.limit,
        })
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
      // 打开编辑框
      async openEditDialog(id) {
        this.resetFormModel()
        let res = await Api.Detail({id: id});
        if (res && res['code'] == '0') {
          this.formModel = Object.assign({}, res['target']);
          this.actionVisible = true;
        }
        this.$message.success(res.message);
      },
    },
  }
</script>

<style scoped>

</style>
